import $ from "jquery";

const toTop = '.js-toTop',
      active = '-active';

let scroll,
    windowHeight,
    footerPosition,
    position,
    buttonRadius,
    bottomSpace;

$(toTop).hide();

$(window).scroll(function () {
  scroll = $(window).scrollTop();
  if(scroll > 100) {
    $(toTop).fadeIn().addClass(active);
  } else {
    $(toTop).fadeOut().removeClass(active);
  }

  if(window.matchMedia('(min-width: 768px)').matches){
    buttonRadius = 28;
    bottomSpace = 36;
  } else {
    buttonRadius = 25;
    bottomSpace = 20;
  }

  windowHeight = window.innerHeight;
  footerPosition =  $('.js-footer').offset().top;
  if(scroll + windowHeight >= (footerPosition + buttonRadius)) {
    position = (scroll + windowHeight) - footerPosition - buttonRadius;
    $(toTop).css('bottom', position);
  } else {
    if($(toTop).hasClass(active)){
      $(toTop).css('bottom', bottomSpace + 'px');
    }
  }
});

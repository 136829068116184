import $ from "jquery";
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

let cnt,
    swiper,
    thumbSwiper;

// スライドの数を数える
cnt = $("#js-projectsThumbSwiper .swiper-slide").length;

if(cnt > 1) {
  thumbSwiper = new Swiper("#js-projectsThumbSwiper", {
    breakpoints: {
      835: {
        centeredSlides: false,
      },
    },
    centeredSlides: false,
    spaceBetween: 12,
    slidesPerView: "auto",
    watchSlidesProgress: true,
  });

  swiper = new Swiper("#js-projectsSwiper", {
    spaceBetween: 0,
    thumbs: {
      swiper: thumbSwiper,
    },
  });
}

import $ from "jquery";
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

var swiper = new Swiper("#js-homeProjectsSwiper", {
  breakpoints: {
    768: {
      navigation: {
        nextEl: "#js-homeProjectsTools .swiper-button-next",
        prevEl: "#js-homeProjectsTools .swiper-button-prev",
      },
      spaceBetween: 40,
    },
  },
  pagination: {
    el: "#js-homeProjectsTools .swiper-pagination",
    type: "progressbar",
  },
  slidesPerView: "auto",
  spaceBetween: 20,
});

import $ from "jquery";

const target = '.js-navTarget';

let windowHeight,
    localNavHeight,
    headerHeight;

$('.js-navTrigger').hover(
  (event) => {
    windowHeight = window.innerHeight;
    headerHeight =  $('.js-header').height();
    localNavHeight = $(event.currentTarget).children(target).height();
    if(windowHeight >= (headerHeight + localNavHeight)) {
      $(event.currentTarget).children(target).css('height', 'auto');
      $('body').removeAttr('style');
    } else {
      $(event.currentTarget).children(target).css('height', 'calc(100% - ' + headerHeight + 'px)');
      $('body').css('overflow', 'hidden');
    }
  },
  (event) => {
    $(event.currentTarget).children(target).removeAttr('style');
    $('body').removeAttr('style');
  }
)

import $ from "jquery";

$('.js-drawerNavOpen').on('click', (event) => {
  $('body').css('overflow', 'hidden');
  $('.js-drawerNav').slideDown();
  return false;
});

$('.js-drawerNavClose').on('click', (event) => {
  $('body').removeAttr('style');
  $('.js-drawerNav').slideUp();
  return false;
});

$('.js-drawerNavTrigger').on('click', (event) => {
  $(event.currentTarget).toggleClass('-active');
  $(event.currentTarget).next('.js-drawerNavTarget').slideToggle();
  return false;
});
